import { Table, Group, Text, ScrollArea, Card } from "@mantine/core";

export function TimeTableList({ data }) {
  const rows = data.map((item, index) => (
    <tr key={item.id}>
      <td>
        <Group spacing="sm">
          <div>
            <Text size="sm" weight={500}>
              {index + 1}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Text size="sm">
          <span className="fw-bold">{item.course}</span>
        </Text>
      </td>
      <td>
        <Text size="sm">
          Level {item.level} {item.program} Students
        </Text>
      </td>
      <td>
        <Text size="sm"> {item.day}</Text>
      </td>
      <td>
        <Text size="sm">
          {item.start_time} - {item.end_time}
        </Text>
      </td>
      <td>
        <Text size="sm">
          {item.gps_long}, {item.gps_lat}
        </Text>
      </td>
    </tr>
  ));

  return (
    <Card shadow="sm" radius="md" withBorder={false}>
      <ScrollArea>
        <Table
          //   striped={true}
          //   highlightOnHover
          //   withBorder
          sx={{ minWidth: 800 }}
          verticalSpacing="xs"
        >
          <thead>
            <tr>
              <th>No</th>
              <th>Couse Title</th>
              <th>Accessible By</th>
              <th>Lecture Day</th>
              <th>Start & End Time</th>
              <th>GPS Coordinate</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    </Card>
  );
}
