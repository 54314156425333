import { Select } from "@mantine/core";

export default function InputSelect({
  label,
  placeholder,
  isRequired,
  isDisabled,
  data,
  formValidateID,
  form,
}) {
  return (
    <div className="mb-2">
      <Select
        placeholder={placeholder}
        label={label}
        withAsterisk={isRequired}
        disabled={isDisabled}
        data={data}
        {...form.getInputProps(formValidateID)}
      />
    </div>
  );
}
