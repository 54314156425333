import { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  AppShell,
  Navbar,
  Header,
  ScrollArea,
  Box,
  Container,
  Modal,
  useMantineTheme,
  Card,
  Indicator,
  Center,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Text, Image, SimpleGrid } from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE, FileWithPath } from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";
import { Photo, X } from "tabler-icons-react";
import { ClientsTable } from "../components/clients-list-table";
import HeaderMenu from "../components/header";
import SideMenuCards from "../components/side-menu-cards";
import TitleBar from "../components/title-bar";
import InputTextField from "../components/input-text";
import InputSelect from "../components/input-select";
import InputButton from "../components/input-button";

export default function Clients() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [passportPhoto, setPassportPhoto] = useState([]);
  // const [passportPhotoBase64, setPassportPhotoBase64] = useState("");
  const passportPhotoBase64 = useRef("");

  const form = useForm({
    initialValues: {
      /////////////// VARIABLE FOR STEP 1 //////////////
      index_number: "",
      surname: "",
      other_names: "",
      program: "",
      level: "",
      mobile_number: "",

      /////////////// VARIABLE FOR STEP 1 //////////////
    },

    // transformValues: (values) => ({
    //   avatar: : `${values.firstName}
    // }),

    // functions will be used to validate values at corresponding key
    validate: {
      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
      index_number: (value) => value === "",
      surname: (value) => value === "",
      other_names: (value) => value === "",
      program: (value) => value === "",
      level: (value) => value === "",
      mobile_number: (value) => value === "" || value.length != 10,

      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
      ///////////////// VALIDATION FOR STEP 2 ////////////////////////
      ///////////////// VALIDATION FOR STEP 3 ////////////////////////
    },
  });

  // const baseURL = "http://localhost/attendance-server"; //http://attendance.getenjoyment.net/api/
  const baseURL = "https://attendance.x10.mx/api"; //http://attendance.getenjoyment.net/api/

  const client = axios.create({
    baseURL: baseURL,
  });

  useEffect(() => {
    getStudents();
  }, []);

  const getStudents = () => {
    client.get(`/get_students.php`).then((response) => {
      console.log(response.data.data);
      setClientsData(response.data.data);
      // const users = response.data;
      // this.setState({ users });
    });
  };

  const deleteStudent = async (indexNumber) => {
    await client
      .post("/delete_student.php", {
        index_number: indexNumber,
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);

        if (response.data.status === "success") {
          saveStudentDone();
        } else {
          alert(response.data.message);
        }
      });
  };

  const sendSMS = async (studentInfo) => {
    console.log(studentInfo);
    await client
      .post("/send_sms.php", {
        student_name: studentInfo["surname"] + " " + studentInfo["other_names"],
        sign_in_code: studentInfo["signin_code"],
        mobile_number: studentInfo["mobile_number"],
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);

        if (response.data.status === "success") {
          alert(
            "SMS sent to " +
              studentInfo["surname"] +
              " " +
              studentInfo["other_names"]
          );
        }
      });
  };

  const openNewClientModal = () => {
    setOpened(true);
  };

  const closeNewClientModal = () => {
    setOpened(false);
  };

  const setIsLoadingFalse = () => {};

  const saveStudent = async () => {
    setIsLoading(true);

    await client
      .post("/add_student.php", {
        surname: form.values.surname,
        other_names: form.values.other_names,
        index_number: form.values.index_number,
        level: form.values.level,
        program: form.values.program,
        mobile_number: form.values.mobile_number,
        avatar: passportPhotoBase64.current,
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);

        if (response.data.status === "success") {
          saveStudentDone();
        } else {
          alert(response.data.message);
        }
      });
    setIsLoading(false);
  };

  const saveStudentDone = () => {
    getStudents();
    removePhoto("passport");
    form.reset();
    closeNewClientModal();
  };

  const removePhoto = (photo) => {
    if (photo === "passport") {
      setPassportPhoto([]);
      passportPhotoBase64.current = "";
    }
  };

  const showEmptyPhotoError = () => {
    showNotification({ message: "Please fill name field", color: "red" });
  };

  const imageToBase64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      passportPhotoBase64.current = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const passportPhotoPreview = passportPhoto.map((file, index) => {
    const imageUrl = URL.createObjectURL(file);

    // console.log(imageUrl);
    // console.log(file);
    imageToBase64(file);

    return (
      <Indicator
        key={index}
        styles={{ indicator: { backgroundColor: "red", cursor: "pointer" } }}
        label={<X size={11} />}
        inline
        size={22}
        onClick={() => {
          removePhoto("passport");
        }}
      >
        <Image
          key={index}
          src={imageUrl}
          imageProps={{ onLoad: () => URL.revokeObjectURL(imageUrl) }}
        />
      </Indicator>
    );
  });

  //  const goToStep = (value) => {
  //   stepperActive.set(value);
  // };

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          width={{ base: 85 }}
          height="100%"
          p="xs"
          // withBorder={true}
        >
          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            <Box py="md">
              <SideMenuCards activeMenu="Students" />
            </Box>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={56} p="xs" withBorder={false}>
          {<HeaderMenu />}
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <TitleBar
        title="Students"
        buttonLabel="New Students"
        buttonClickFunction={openNewClientModal}
        showButton={true}
      />
      <Container fluid m={0} py={0}>
        {clientsData.length > 0 && (
          <ClientsTable
            data={clientsData}
            deleteStudentFunc={deleteStudent}
            sendCodeFunc={sendSMS}
          />
        )}
      </Container>

      <Modal
        overlayColor={
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.dark[5]
        }
        overlayOpacity={0.55}
        overlayBlur={3}
        opened={opened}
        onClose={() => setOpened(false)}
        title={<p className="fs-4 m-0">New Student Form</p>}
        // centered
        overflow="inside"
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
      >
        {/* Modal content */}
        {/* <LoadingOverlay visible={visible} overlayBlur={2} /> */}
        <Card shadow="sm" radius="md" withBorder={false}>
          <div className="container">
            <div className="row">
              <form
                onSubmit={form.onSubmit(() => {
                  saveStudent();
                })}
              >
                <div className="row">
                  <div className="col-12 mb-3">
                    <SimpleGrid cols={3}>
                      <div></div>
                      {passportPhoto.length === 0 && (
                        <Dropzone
                          accept={IMAGE_MIME_TYPE}
                          onDrop={setPassportPhoto}
                          styles={{
                            inner: {
                              height: "150px",
                            },
                          }}
                        >
                          <Text align="center">
                            Drop Or Click To Select Student Photo
                          </Text>
                          <Center mt={20}>
                            <Photo size={50} strokeWidth={0.5}></Photo>
                          </Center>
                        </Dropzone>
                      )}

                      {passportPhotoPreview}
                    </SimpleGrid>
                  </div>

                  <div className="col-6">
                    <InputTextField
                      label="Index Number"
                      isRequired={true}
                      isDisabled={isLoading}
                      placeholder="0000000000"
                      formValidateID="index_number"
                      form={form}
                    />
                  </div>
                  <div className="col-6">
                    <InputTextField
                      label="Surname"
                      isRequired={true}
                      isDisabled={isLoading}
                      placeholder="Nartey"
                      formValidateID="surname"
                      form={form}
                    />
                  </div>
                  <div className="col-6">
                    <InputTextField
                      label="Other Names"
                      isRequired={true}
                      isDisabled={isLoading}
                      placeholder="Francis"
                      formValidateID="other_names"
                      form={form}
                    />
                  </div>

                  <div className="col-6">
                    <InputSelect
                      label="Program"
                      isRequired={true}
                      isDisabled={isLoading}
                      data={[
                        {
                          value: "BSc. Telecommunications Engineering",
                          label: "BSc. Telecommunications Engineering",
                        },
                        {
                          value: "BSc. Computer Engineering",
                          label: "BSc. Computer Engineering",
                        },
                        {
                          value: "BSc. Mathematics",
                          label: "BSc. Mathematics",
                        },
                        {
                          value: "BSc. Electrical and Electronic Engineering",
                          label: "BSc. Electrical and Electronic Engineering",
                        },
                        {
                          value: "BSc. Informatics",
                          label: "BSc. Informatics",
                        },
                        {
                          value: "BSc. Information Technology",
                          label: "BSc. Information Technology",
                        },
                        {
                          value: "BSc. Mobile Computing",
                          label: "BSc. Mobile Computing",
                        },
                        {
                          value: "BSc. Computer Science",
                          label: "BSc. Computer Science",
                        },
                        {
                          value: "BSc. Software Engineering",
                          label: "BSc. Software Engineering",
                        },
                        {
                          value: "BSc. Business Information Systems",
                          label: "BSc. Business Information Systems",
                        },
                      ]}
                      formValidateID="program"
                      form={form}
                    />
                  </div>
                  <div className="col-6">
                    <InputSelect
                      label="Level"
                      isRequired={true}
                      isDisabled={isLoading}
                      data={[
                        { value: "100", label: "100" },
                        { value: "200", label: "200" },
                        { value: "300", label: "300" },
                        { value: "400", label: "400" },
                      ]}
                      formValidateID="level"
                      form={form}
                    />
                  </div>
                  <div className="col-6">
                    <InputTextField
                      label="Mobile Number"
                      isRequired={true}
                      isDisabled={isLoading}
                      placeholder="0244444444"
                      formValidateID="mobile_number"
                      form={form}
                    />
                  </div>

                  <div className="mt-3 col-6 offset-3">
                    <InputButton
                      label="Save"
                      isLoading={isLoading}
                      //   onClickFunction={signIn}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Modal>
    </AppShell>
  );
}
