import { TextInput, Loader } from "@mantine/core";

export default function InputTextField({
  label,
  placeholder,
  isRequired,
  isDisabled,
  showLoading,
  type,
  formValidateID,
  onInputclick,
  readOnly,
  form,
}) {
  return (
    <div className="mb-2">
      <TextInput
        placeholder={placeholder}
        label={label}
        withAsterisk={isRequired}
        disabled={isDisabled}
        type={type ? type : "text"}
        rightSection={showLoading ? <Loader size="xs" /> : null}
        {...form.getInputProps(formValidateID)}
        onClick={onInputclick ? onInputclick : null}
        readOnly={readOnly}
      />
    </div>
  );
}
