import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import SignIn from "./pages/sign-in";
import Dashboard from "./pages/dashboard";
import Clients from "./pages/clients";
import Loans from "./pages/loans";
import Settings from "./pages/settings";
import Timetable from "./pages/timetable";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SignIn />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/students" element={<Clients />}></Route>
        <Route path="/attendance" element={<Loans />}></Route>
        <Route path="/timetable" element={<Timetable />}></Route>
        {/* <Route path="/settings" element={<Settings />}></Route> */}
        {/* <Route path="home-insurance" element={<HomePage />}></Route>
        <Route path="payment" element={<ProductPayment />}></Route>
        <Route path="order-complete" element={<OrderComplete />}></Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
