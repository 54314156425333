import { DatePicker } from "@mantine/dates";

export default function InputDatePicker({
  label,
  placeholder,
  isRequired,
  isDisabled,
  formValidateID,
  form,
}) {
  return (
    <div className="mb-2">
      <DatePicker
        placeholder={placeholder}
        label={label}
        withAsterisk={isRequired}
        disabled={isDisabled}
        {...form.getInputProps(formValidateID)}
      />
    </div>
  );
}
