import {
  createStyles,
  Text,
  Card,
  SimpleGrid,
  Container,
  ThemeIcon,
  Image,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { Clock, Report, School, Users } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
    cursor: "pointer",
    height: "100%",
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));

export default function FeaturesCards() {
  const { classes } = useStyles();

  return (
    <Container size="lg" pt="xl" pb="xl" fluid>
      <Text
        color="blue"
        // className={classes.description}
        align="center"
        mt="md"
        size={32}
        weight="bold"
        // mb="lg"
      >
        WELCOME TO THE ATTENDANCE
        <br />
        SYSTEM ADMIN PORTAL
      </Text>

      <Text
        color="dimmed"
        // className={classes.description}
        align="center"
        mb="xl"
      >
        Select an item to proceed
      </Text>

      {/* <p className="text-primary fs-4 mb-1">Quick Menu</p> */}

      <SimpleGrid
        cols={4}
        spacing="xl"
        // mt={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        {/* {features} */}

        <Card
          shadow="md"
          radius="md"
          className={classes.card}
          p="xl"
          onClick={() => alert("Module Coming Soon")}
        >
          <ThemeIcon
            variant="light"
            className={classes.itemIcon}
            size={60}
            radius="md"
            color="green"
          >
            <School size={36} strokeWidth={1} />
          </ThemeIcon>
          <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
            Courses
          </Text>
          <Text size="sm" color="dimmed" mt="sm">
            View, edit or or add courses
          </Text>
        </Card>

        <Link
          // className="nav-link small--text me-1 ms-1"
          to="/timetable"
          // state={packageData}
        >
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <ThemeIcon
              variant="light"
              className={classes.itemIcon}
              size={60}
              radius="md"
              color="yellow"
            >
              <Report size={36} strokeWidth={1} />
            </ThemeIcon>
            <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
              Timetable
            </Text>
            <Text size="sm" color="dimmed" mt="sm">
              View, edit or create timetables
            </Text>
          </Card>
        </Link>

        <Link
          // className="nav-link small--text me-1 ms-1"
          to="/students"
          // state={packageData}
        >
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <ThemeIcon
              variant="light"
              className={classes.itemIcon}
              size={60}
              radius="md"
              color="orange"
            >
              <Users size={36} strokeWidth={1} />
            </ThemeIcon>
            <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
              Students
            </Text>
            <Text size="sm" color="dimmed" mt="sm">
              Add, update or delete student
            </Text>{" "}
          </Card>
        </Link>

        <Link
          // className="nav-link small--text me-1 ms-1"
          to="/attendance"
          // state={packageData}
        >
          <Card shadow="md" radius="md" className={classes.card} p="xl">
            <ThemeIcon
              variant="light"
              className={classes.itemIcon}
              size={60}
              radius="md"
              color="violet"
            >
              <Clock size={36} strokeWidth={1} />
            </ThemeIcon>
            <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
              Attendance
            </Text>
            <Text size="sm" color="dimmed" mt="sm">
              Add, update or delete attendace
            </Text>
          </Card>
        </Link>
      </SimpleGrid>
    </Container>
  );
}

// const menuCard = ({ classes, icon, label }) => {
//   return (
//     <Card shadow="md" radius="md" className={classes.card} p="xl">
//       <Users size={50} color="blue" />
//       <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
//         {label}
//       </Text>
//       {/* <Text size="sm" color="dimmed" mt="sm">
//             {feature.description}
//           </Text> */}
//     </Card>
//   );
// };
