import { useState } from "react";
import {
  Avatar,
  Table,
  Group,
  Text,
  ActionIcon,
  Menu,
  ScrollArea,
  Card,
  Tooltip,
  Button,
  Center,
} from "@mantine/core";
import {
  Pencil,
  Messages,
  Trash,
  Printer,
  Wallet,
  DotsVertical,
  Message,
} from "tabler-icons-react";
import { Modal } from "@mantine/core";

export function ClientsTable({ data, deleteStudentFunc, sendCodeFunc }) {
  const [openedSendCode, setOpenedSendCode] = useState(false);
  const [openedDeleteStudent, setOpenedDeleteStudent] = useState(false);
  const [studentData, setStudentData] = useState({});
  const baseURL = "https://attendance.x10.mx/api";

  const rows = data.map((item) => (
    <tr key={item.id}>
      <td>
        <Group spacing="sm">
          <Avatar
            size={40}
            src={baseURL + "/images/" + item.avatar}
            radius={40}
          />
          <div>
            <Text size="sm" weight={500}>
              {item.surname} {item.other_names}
            </Text>
            <Text color="dimmed" size="xs">
              {item.index_number}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Text size="sm">{item.program}</Text>
        <Text size="xs" color="dimmed">
          Level {item.level}
        </Text>
      </td>
      <td>
        <Text size="sm">{item.mobile_number}</Text>
        {/* <Text size="xs" color="dimmed">
          Rate
        </Text> */}
      </td>
      <td>
        <Group spacing={0} position="right">
          <Tooltip
            label="SMS Sign In Code"
            position="left"
            transitionDuration={0}
          >
            <ActionIcon onClick={() => openSendCode(item)}>
              <Message size={16} color="blue" />
            </ActionIcon>
          </Tooltip>

          <Tooltip
            label="Remove Student"
            position="left"
            transitionDuration={0}
          >
            <ActionIcon onClick={() => openDeleteStudent(item)}>
              <Trash size={16} color="red" />
            </ActionIcon>
          </Tooltip>
        </Group>
      </td>
    </tr>
  ));

  const openSendCode = (studentInfo) => {
    setStudentData(studentInfo);
    setOpenedSendCode(true);
  };

  const sendCode = () => {
    console.log(studentData["mobile_number"]);
    sendCodeFunc(studentData);
    setOpenedSendCode(false);
  };

  const openDeleteStudent = (studentInfo) => {
    setStudentData(studentInfo);
    setOpenedDeleteStudent(true);
  };

  const deleteStudent = () => {
    console.log(studentData["index_number"]);
    deleteStudentFunc(studentData["index_number"]);
    setOpenedDeleteStudent(false);
  };

  return (
    <>
      <Modal
        overlayOpacity={0.55}
        overlayBlur={3}
        opened={openedSendCode}
        onClose={() => setOpenedSendCode(false)}
        title="Confirm"
        centered
        // overflow="inside"
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="md"
      >
        <Text size="sm" mb="md">
          Send sign in code to {studentData["surname"]}{" "}
          {studentData["other_names"]} ({studentData["index_number"]})?
        </Text>
        <Center>
          <Group>
            <Button onClick={() => setOpenedSendCode(false)} color="gray">
              Cancel
            </Button>
            <Button onClick={() => sendCode()}>Confirm</Button>
          </Group>
        </Center>
      </Modal>

      <Modal
        overlayOpacity={0.55}
        overlayBlur={3}
        opened={openedDeleteStudent}
        onClose={() => setOpenedDeleteStudent(false)}
        title="Confirm"
        centered
        // overflow="inside"
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="md"
      >
        <Text size="sm" mb="md">
          Delete {studentData["surname"]} {studentData["other_names"]} (
          {studentData["index_number"]})?
        </Text>
        <Center>
          <Group>
            <Button onClick={() => setOpenedDeleteStudent(false)} color="gray">
              Cancel
            </Button>
            <Button onClick={() => deleteStudent()} color="red">
              Confirm
            </Button>
          </Group>
        </Center>
      </Modal>

      <Card shadow="sm" radius="md" withBorder={false}>
        <ScrollArea>
          <Table
            //   striped={true}
            //   highlightOnHover
            //   withBorder
            sx={{ minWidth: 800 }}
            verticalSpacing="xs"
          >
            <tbody>{rows}</tbody>
          </Table>
        </ScrollArea>
      </Card>
    </>
  );
}
