import { useState } from "react";
import { Avatar, Card, Center, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import InputButton from "../components/input-button";
import InputTextField from "../components/input-text";
import { User } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import HelpingHandVenturesLogo from "../assets/logos/helping-hand.jpg";

export default function SignIn() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      /////////////// VARIABLE FOR STEP 1 //////////////
      email: "",
      password: "",

      /////////////// VARIABLE FOR STEP 1 //////////////
    },

    // functions will be used to validate values at corresponding key
    validate: {
      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
      email: (value) => value === "",
      password: (value) => value === "",
      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
    },
  });

  const signIn = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate(
        "/dashboard"
        // { state: { packageData, paymentDetails } },
        // { replace: true }
      );
    }, 4000);
  };

  return (
    <div className="container vh-100">
      <div className="row h-100">
        <div className="col-4 offset-4 d-flex align-items-center justify-content-center">
          <Card shadow="md" p="md" radius="md" withBorder>
            <Card.Section>
              <div className="m-4">
                <p
                  className="fs-4 fw-bold text-center mt-5"
                  // style={{ width: "300px" }}
                >
                  Atendance System
                </p>

                <Center>
                  <Text size="sm" color="dimmed" mb="lg">
                    Please sign in to continue
                  </Text>
                </Center>

                <form
                  onSubmit={form.onSubmit(() => {
                    signIn();
                  })}
                >
                  <InputTextField
                    label="Email"
                    isRequired={true}
                    isDisabled={isLoading}
                    placeholder="mail@domain.com"
                    formValidateID="email"
                    form={form}
                  />
                  <InputTextField
                    label="Password"
                    isRequired={true}
                    isDisabled={isLoading}
                    type="password"
                    placeholder="********"
                    formValidateID="password"
                    form={form}
                  />
                  <div className="mt-5">
                    <InputButton
                      label="Sign In"
                      isLoading={isLoading}
                      // onClickFunction={signIn}
                    />
                  </div>
                </form>
              </div>
            </Card.Section>
          </Card>
        </div>
      </div>
    </div>
  );
}
