import { useState, useEffect, Fragment } from "react";
import {
  Modal,
  Card,
  useMantineTheme,
  Divider,
  createStyles,
  Avatar,
  Text,
  Group,
  Flex,
  TextInput,
  Loader,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import InputButton from "./input-button";

export default function ModalSearchClient({
  opened,
  isLoading,
  closeModal,
  selectAction,
}) {
  const [clients, setClients] = useState([]);
  const [clientsSearchLoading, setClientsSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useDebouncedState("", 500);
  const theme = useMantineTheme();

  useEffect(() => {
    if (searchValue === "") {
      setClients([]);
    } else {
      setClientsSearchLoading(true);
      setTimeout(() => {
        setClients(clientsData);
        setClientsSearchLoading(false);
      }, 2000);
    }
  }, [searchValue]);

  const clientsData = [
    {
      id: 1,
      client_id: 46756,
      avatar:
        "https://images.unsplash.com/photo-1624298357597-fd92dfbec01d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80",
      name: "Robert Wolfkisser",
      active_loan: false,
    },
    {
      id: 2,
      client_id: 38745,
      avatar:
        "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80",
      name: "Jill Jailbreaker",
      active_loan: true,
    },
    {
      id: 3,
      client_id: 36745,
      avatar:
        "https://images.unsplash.com/photo-1632922267756-9b71242b1592?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80",
      name: "Henry Silkeater",
      active_loan: false,
    },
    {
      id: 4,
      client_id: 36455,
      avatar:
        "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80",
      name: "Bill Horsefighter",
      active_loan: false,
    },
  ];

  return (
    <Modal
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.dark[9]
      }
      overlayOpacity={0.55}
      overlayBlur={5}
      opened={opened}
      onClose={() => closeModal()}
      title={<p className="fs-4 m-0">Client Search</p>}
      overflow="inside"
      closeOnClickOutside={false}
      closeOnEscape={false}
      size="lg"
    >
      {/* Modal content */}

      <Card shadow="sm" radius="md" withBorder={false}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-2">
                <TextInput
                  placeholder="Enter Client ID or Name or Ghana Card ID"
                  type="text"
                  rightSection={
                    clientsSearchLoading ? <Loader size="xs" /> : null
                  }
                  defaultValue={searchValue}
                  style={{ flex: 1 }}
                  onChange={(event) =>
                    setSearchValue(event.currentTarget.value)
                  }
                />
              </div>
            </div>

            <div>
              {clients.map((client, index) => (
                <Fragment key={client.client_id}>
                  <Flex justify="space-between" align="center" mb="xs">
                    <Group noWrap>
                      <Avatar src={client.avatar} size={60} radius="md" />
                      <div>
                        <Text size="xs" weight={700}>
                          {client.name}
                        </Text>

                        <Text size="xs" weight={500}>
                          ID: {client.client_id}
                        </Text>

                        <Group noWrap spacing={10}>
                          {!client.active_loan && (
                            <Text size="xs" weight={700} color="green">
                              No Active Loan
                            </Text>
                          )}

                          {client.active_loan && (
                            <Text size="xs" weight={700} color="red">
                              Loan Active
                            </Text>
                          )}
                        </Group>
                      </div>
                    </Group>
                    {!client.active_loan && (
                      <InputButton
                        label="Select"
                        isLoading={isLoading}
                        onClickFunction={() => selectAction(client)}
                      />
                    )}

                    {client.active_loan && (
                      <InputButton label="Select" isDisabled={true} />
                    )}
                  </Flex>
                  {index !== clients.length - 1 && <Divider mb="xs" />}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
