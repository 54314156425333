import {
  createStyles,
  Title,
  Container,
  Group,
  // ActionIcon,
  // Menu,
  Flex,
} from "@mantine/core";
// import {
//   Messages,
//   Note,
//   ReportAnalytics,
//   Trash,
//   Dots,
//   DotsVertical,
// } from "tabler-icons-react";
import InputButton from "./input-button";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },
}));

export default function TitleBar({
  title,
  buttonClickFunction,
  buttonLabel,
  showButton,
}) {
  const { classes } = useStyles();

  return (
    <Container size="lg" pt={0} pb="lg" fluid>
      <Flex justify="space-between" align="center" direction="row" wrap="wrap">
        <Title className={classes.title} align="left" mt={0}>
          {title}
        </Title>
        <Group spacing={0}>
          {showButton && (
            <InputButton
              label={buttonLabel}
              onClickFunction={buttonClickFunction}
            />
          )}

          {/*  <Menu transition="pop" withArrow position="bottom-end">
            <Menu.Target>
              <ActionIcon>
                <DotsVertical size={16} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={<Messages size={16} />}
                onClick={() => console.log("Good")}
              >
                New Loan
              </Menu.Item>
              <Menu.Item icon={<Note size={16} />}>Add note</Menu.Item>
              <Menu.Item icon={<ReportAnalytics size={16} />}>
                Analytics
              </Menu.Item>
              <Menu.Item icon={<Trash size={16} />} color="red">
                Terminate contract
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>*/}
        </Group>
      </Flex>

      {/* <Divider my="sm" /> */}
    </Container>
  );
}
