import { useState, useEffect } from "react";
import axios from "axios";
import {
  AppShell,
  Navbar,
  Header,
  ScrollArea,
  Box,
  Container,
  useMantineTheme,
  SimpleGrid,
  Text,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import HeaderMenu from "../components/header";
import SideMenuCards from "../components/side-menu-cards";
import TitleBar from "../components/title-bar";
import { LoansTable } from "../components/loans-list-table";
import ModalNewLoan from "../components/modal-new-loan";

export default function Loans() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [value, onChange] = useState(new Date());

  const form = useForm({
    initialValues: {
      /////////////// VARIABLE FOR STEP 1 //////////////
      surname: "",
      otherNames: "",
      dateOfbirth: "",
      homeAddress: "",
      mobileNumber: "",
      ghanaCardID: "",
      maritalStatus: "",
      children: "",
      dependants: "",
      occupation: "",
      workAddress: "",

      /////////////// VARIABLE FOR STEP 1 //////////////
    },

    // functions will be used to validate values at corresponding key
    validate: {
      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
      //   surname: (value) => value === "",
      //   otherNames: (value) => value === "",
      //   dateOfbirth: (value) => value === "",
      //   homeAddress: (value) => value === "",
      //   mobileNumber: (value) => value === "",
      //   ghanaCardID: (value) => value === "",
      //   maritalStatus: (value) => value === "",
      //   children: (value) => value === "",
      //   dependants: (value) => value === "",
      //   occupation: (value) => value === "",
      //   workAddress: (value) => value === "",
      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
      ///////////////// VALIDATION FOR STEP 2 ////////////////////////
      ///////////////// VALIDATION FOR STEP 3 ////////////////////////
    },
  });

  const baseURL = "https://attendance.x10.mx/api";

  const client = axios.create({
    baseURL: baseURL,
  });

  useEffect(() => {
    getAttendances(); ///get_attendances.php
  }, []);

  const getAttendances = async () => {
    console.log(value);
    await client
      .post("/get_attendances.php", {
        date: value,
      })
      .then((response) => {
        console.log(response.data.data);
        setClientsData(response.data.data);
      });

    // client.post(`/get_attendances.php`).then((response) => {
    //   console.log(response.data.data);
    //   setClientsData(response.data.data);
    // });
  };

  const openNewClientModal = () => {
    setOpened(true);
  };

  const closeNewClientModal = () => {
    setOpened(false);
  };

  const showEmptyPhotoError = (color) => {
    showNotification({ message: "Please fill name field", color: "red" });
  };

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          width={{ base: 85 }}
          height="100%"
          p="xs"
          // withBorder={true}
        >
          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            <Box py="md">
              <SideMenuCards activeMenu="Attendance" />
            </Box>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={56} p="xs" withBorder={false}>
          {<HeaderMenu />}
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <TitleBar
        title="Attendance"
        buttonLabel="Refresh"
        buttonClickFunction={getAttendances}
        showButton={true}
      />

      <Container fluid m={0} py={0} mb="md">
        <Text>Attendance Today</Text>
        <SimpleGrid
          cols={3}
          spacing="xl"
          breakpoints={[{ maxWidth: "md", cols: 1 }]}
        >
          {/* <DatePicker value={value} onChange={onChange} label="Filter Date" /> */}
        </SimpleGrid>
      </Container>

      <Container fluid m={0} py={0}>
        <LoansTable data={clientsData} />
      </Container>
      <ModalNewLoan opened={opened} closeModal={closeNewClientModal} />
    </AppShell>
  );
}
