import {
  AppShell,
  Navbar,
  Header,
  ScrollArea,
  Box,
  Container,
  Grid,
} from "@mantine/core";
import FeaturesCards from "../components/card";
import CardStatsQucik from "../components/card-stats-quick";
import HeaderMenu from "../components/header";
import SideMenuCards from "../components/side-menu-cards";
import TitleBar from "../components/title-bar";

export default function dashboard() {
  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          width={{ base: 85 }}
          height="100%"
          p="xs"
          // withBorder={true}
        >
          {/* <ScrollArea style={{ height: "100%" }} scrollbarSize={7}>
              {<SideMenuCards />}
            </ScrollArea> */}
          {/* <Navbar.Section>
            <p className="fs-4 mb-1 text-center">Menu</p>
            <hr className="m-0 p-0 border-secondary" />
          </Navbar.Section> */}
          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            <Box py="md">
              <SideMenuCards activeMenu="Dashboard" />
            </Box>
          </Navbar.Section>
          {/* <Navbar.Section>
            <p>Down</p>
          </Navbar.Section> */}
        </Navbar>
      }
      header={
        <Header height={56} p="xs" withBorder={false}>
          {<HeaderMenu />}
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <TitleBar title="Dashboard" />
      <Container fluid m={0} py={0}>
        {/* <p className="text-primary fs-4 mb-1">Quick Stats</p> */}
        {/* <Grid>
          <Grid.Col span={4}>
            <CardStatsQucik colour="violet" />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardStatsQucik colour="teal" />
          </Grid.Col>
          <Grid.Col span={4}>
            <CardStatsQucik colour="red" />
          </Grid.Col>
        </Grid> */}
      </Container>
      {<FeaturesCards />}
    </AppShell>
  );
}
