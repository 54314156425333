import { useState, useEffect, Fragment } from "react";
import {
  Modal,
  Card,
  useMantineTheme,
  Divider,
  createStyles,
  Avatar,
  Text,
  Group,
  Flex,
  TextInput,
  Loader,
} from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import InputButton from "./input-button";

export default function ModalSearchGuarantor({
  opened,
  isLoading,
  closeModal,
  selectAction,
}) {
  const [guarantors, setGuarantors] = useState([]);
  const [guarantorsSearchLoading, setGuarantorsSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useDebouncedState("", 500);
  const theme = useMantineTheme();

  useEffect(() => {
    if (searchValue === "") {
      setGuarantors([]);
    } else {
      setGuarantorsSearchLoading(true);
      setTimeout(() => {
        setGuarantors(guarantorData);
        setGuarantorsSearchLoading(false);
      }, 2000);
    }
  }, [searchValue]);

  const guarantorData = [
    {
      id: 1,
      guarantor_id: 12345,
      avatar:
        "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80",
      name: "Eunice Tagoe",
      active_guarantees: 0,
    },
    {
      id: 2,
      guarantor_id: 67891,
      avatar:
        "https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80",
      name: "Mary Ashong",
      active_guarantees: 3,
    },
    {
      id: 3,
      guarantor_id: 98234,
      avatar:
        "https://images.unsplash.com/photo-1632922267756-9b71242b1592?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=250&q=80",
      name: "Fred Nartey",
      active_guarantees: 0,
    },
  ];

  return (
    <Modal
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.dark[9]
      }
      overlayOpacity={0.55}
      overlayBlur={5}
      opened={opened}
      onClose={() => closeModal()}
      title={<p className="fs-4 m-0">Guarantor Search</p>}
      overflow="inside"
      closeOnClickOutside={false}
      closeOnEscape={false}
      size="lg"
    >
      {/* Modal content */}

      <Card shadow="sm" radius="md" withBorder={false}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-2">
                <TextInput
                  placeholder="Enter Guarantor ID or Name or Ghana Card ID"
                  type="text"
                  rightSection={
                    guarantorsSearchLoading ? <Loader size="xs" /> : null
                  }
                  defaultValue={searchValue}
                  style={{ flex: 1 }}
                  onChange={(event) =>
                    setSearchValue(event.currentTarget.value)
                  }
                />
              </div>
            </div>

            <div>
              {guarantors.map((guarantor, index) => (
                <Fragment key={guarantor.guarantor_id}>
                  <Flex justify="space-between" align="center" mb="xs">
                    <Group noWrap>
                      <Avatar src={guarantor.avatar} size={60} radius="md" />
                      <div>
                        <Text size="xs" weight={700}>
                          {guarantor.name}
                        </Text>

                        <Text size="xs" weight={500}>
                          ID: {guarantor.guarantor_id}
                        </Text>

                        <Group noWrap spacing={10}>
                          <Text size="xs" weight={700} color="green">
                            Active Guarantees:{" "}
                            <span className="fw-bold">
                              {guarantor.active_guarantees}
                            </span>
                          </Text>
                        </Group>
                      </div>
                    </Group>

                    <InputButton
                      label="Select"
                      isLoading={isLoading}
                      onClickFunction={() => selectAction(guarantor)}
                    />
                  </Flex>
                  {index !== guarantors.length - 1 && <Divider mb="xs" />}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
