import {
  createStyles,
  AppShell,
  Navbar,
  Header,
  ScrollArea,
  Box,
  Container,
  Grid,
  Card,
  Tabs,
} from "@mantine/core";
import HeaderMenu from "../components/header";
import SideMenuCards from "../components/side-menu-cards";
import TitleBar from "../components/title-bar";
import { UserCircle, Adjustments, Users } from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
  card: {
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
    cursor: "pointer",
    height: "100%",
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
    },
  },
}));

export default function Settings() {
  const { classes } = useStyles();

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          width={{ base: 85 }}
          height="100%"
          p="xs"
          // withBorder={true}
        >
          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            <Box py="md">
              <SideMenuCards activeMenu="System Settings" />
            </Box>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={56} p="xs" withBorder={false}>
          {<HeaderMenu />}
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <TitleBar title="Settings" />
      <Container fluid m={0} py={0}>
        <Card shadow="md" radius="md" className={classes.card} p="xl">
          <Tabs variant="pills" defaultValue="gallery">
            <Tabs.List>
              <Tabs.Tab value="settings" icon={<UserCircle size={14} />}>
                Profile & Security
              </Tabs.Tab>
              <Tabs.Tab value="messages" icon={<Users size={14} />}>
                User Accounts
              </Tabs.Tab>
              <Tabs.Tab value="gallery" icon={<Adjustments size={14} />}>
                Loan Configurations
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="gallery">
              <hr />
              Gallery tab content
            </Tabs.Panel>

            <Tabs.Panel value="messages">
              <hr />
              Messages tab content
            </Tabs.Panel>

            <Tabs.Panel value="settings">
              <hr />
              Settings tab content
            </Tabs.Panel>
          </Tabs>
        </Card>
      </Container>
    </AppShell>
  );
}
