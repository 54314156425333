import { Button, Loader } from "@mantine/core";
import "./input-button.css";

export default function InputButton({
  label,
  isLoading,
  onClickFunction,
  isDisabled,
  loadingText,
}) {
  const clickFunction = () => {
    onClickFunction();
  };

  return (
    <div className="">
      <Button
        // disabled={isDisabled}
        disabled={isLoading}
        type="submit"
        fullWidth
        loading={isLoading}
        loaderPosition="center"
        onClick={onClickFunction ? clickFunction : null}
        loaderProps={{ size: "sm", color: "blue" }}
      >
        {isLoading ? "" : label}
      </Button>
    </div>
  );
}
