import { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  AppShell,
  Navbar,
  Header,
  ScrollArea,
  Box,
  Container,
  Modal,
  useMantineTheme,
  Card,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import HeaderMenu from "../components/header";
import SideMenuCards from "../components/side-menu-cards";
import TitleBar from "../components/title-bar";
import InputTextField from "../components/input-text";
import InputSelect from "../components/input-select";
import InputButton from "../components/input-button";
import { TimeTableList } from "../components/timetable-list-table";
import { TimeInput } from "@mantine/dates";

export default function Timetable() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  // const [passportPhotoBase64, setPassportPhotoBase64] = useState("");
  const passportPhotoBase64 = useRef("");

  const form = useForm({
    initialValues: {
      /////////////// VARIABLE FOR STEP 1 //////////////
      course: "",
      day: "",
      program: "",
      level: "",
      start_time: new Date(),
      end_time: new Date(),
      gps_long: "",
      gps_lat: "",

      /////////////// VARIABLE FOR STEP 1 //////////////
    },

    // transformValues: (values) => ({
    //   avatar: : `${values.firstName}
    // }),

    // functions will be used to validate values at corresponding key
    validate: {
      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
      course: (value) => value === "",
      day: (value) => value === "",
      program: (value) => value === "",
      level: (value) => value === "",
      start_time: (value) => value === "",
      end_time: (value) => value === "",
      gps_long: (value) => value === "",
      gps_lat: (value) => value === "",

      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
      ///////////////// VALIDATION FOR STEP 2 ////////////////////////
      ///////////////// VALIDATION FOR STEP 3 ////////////////////////
    },
  });

  const baseURL = "https://attendance.x10.mx/api"; //http://attendance.getenjoyment.net/api/

  const client = axios.create({
    baseURL: baseURL,
  });

  useEffect(() => {
    getTimetable();
  }, []);

  const getTimetable = () => {
    client.get(`/get_timetable.php`).then((response) => {
      console.log(response.data.data);
      setClientsData(response.data.data);
      // const users = response.data;
      // this.setState({ users });
    });
  };

  const deleteStudent = async (indexNumber) => {
    await client
      .post("/delete_student.php", {
        index_number: indexNumber,
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);

        if (response.data.status === "success") {
          saveTimetableDone();
        } else {
          alert(response.data.message);
        }
      });
  };

  const openNewClientModal = () => {
    setOpened(true);
  };

  const closeNewClientModal = () => {
    setOpened(false);
  };

  const saveTimetable = async () => {
    setIsLoading(true);

    await client
      .post("/add_timetable.php", {
        course: form.values.course,
        day: form.values.day,
        program: form.values.program,
        level: form.values.level,
        start_time: form.values.start_time,
        end_time: form.values.end_time,
        gps_long: form.values.gps_long,
        gps_lat: form.values.gps_lat,
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);

        if (response.data.status === "success") {
          saveTimetableDone();
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
    setIsLoading(false);
  };

  const saveTimetable123 = async () => {
    setIsLoading(true);
    console.log("1");
    await fetch(
      "https://attendance.x10.mx/api/add_timetable.php",
      {
        method: "post",
        body: {
          course: form.values.course,
          day: form.values.day,
          program: form.values.program,
          level: form.values.level,
          start_time: form.values.start_time,
          end_time: form.values.end_time,
          gps_long: form.values.gps_long,
          gps_lat: form.values.gps_lat,
        },
      }
    )
      .then((response) => {
        console.log("2");
        console.log(response);
        console.log(response.data);
        console.log("3");
        if (response.data.status === "success") {
          saveTimetableDone();
        } else {
          alert(response.data.message);
        }
      })
      // .then(function (data) {
      //   // ChromeSamples.log("Created Gist:", data.html_url);
      // });
      .catch((error) => {
        console.log(error);
        alert(error);
      });

    setIsLoading(false);
  };

  const saveTimetableDone = () => {
    getTimetable();
    form.reset();
    closeNewClientModal();
  };

  return (
    <AppShell
      padding="md"
      navbar={
        <Navbar
          width={{ base: 85 }}
          height="100%"
          p="xs"
          // withBorder={true}
        >
          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            <Box py="md">
              <SideMenuCards activeMenu="Timetable" />
            </Box>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={56} p="xs" withBorder={false}>
          {<HeaderMenu />}
        </Header>
      }
      styles={(theme) => ({
        main: {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <TitleBar
        title="Timetable"
        buttonLabel="Add New"
        buttonClickFunction={openNewClientModal}
        showButton={true}
      />
      <Container fluid m={0} py={0}>
        {clientsData.length > 0 && <TimeTableList data={clientsData} />}
      </Container>

      <Modal
        overlayColor={
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.dark[5]
        }
        overlayOpacity={0.55}
        overlayBlur={3}
        opened={opened}
        onClose={() => setOpened(false)}
        // title={<p className="fs-4 m-0">New Student Form</p>}
        // centered
        overflow="inside"
        closeOnClickOutside={false}
        closeOnEscape={false}
        size="xl"
      >
        {/* Modal content */}
        {/* <LoadingOverlay visible={visible} overlayBlur={2} /> */}
        <Card shadow="sm" radius="md" withBorder={false}>
          <div className="container">
            <div className="row">
              <form
                onSubmit={form.onSubmit(() => {
                  saveTimetable();
                })}
              >
                <div className="row">
                  <div className="col-6">
                    <InputTextField
                      label="Course Title"
                      isRequired={true}
                      isDisabled={isLoading}
                      placeholder="Java Programming"
                      formValidateID="course"
                      form={form}
                    />
                  </div>

                  <div className="col-6">
                    <InputSelect
                      label="Course Day"
                      isRequired={true}
                      isDisabled={isLoading}
                      data={[
                        {
                          value: "Monday",
                          label: "Monday",
                        },
                        {
                          value: "Tuesday",
                          label: "Tuesday",
                        },
                        {
                          value: "Wednesday",
                          label: "Wednesday",
                        },
                        {
                          value: "Thursday",
                          label: "Thursday",
                        },
                        {
                          value: "Friday",
                          label: "Friday",
                        },
                        {
                          value: "Saturday",
                          label: "Saturday",
                        },
                        {
                          value: "Sunday",
                          label: "Sunday",
                        },
                      ]}
                      formValidateID="day"
                      form={form}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <TimeInput
                      label="Start Time"
                      format="12"
                      withAsterisk={true}
                      {...form.getInputProps("start_time")}
                    />
                  </div>
                  <div className="col-6 mb-2">
                    <TimeInput
                      label="End Time"
                      format="12"
                      withAsterisk={true}
                      {...form.getInputProps("end_time")}
                    />
                  </div>
                  <div className="col-6">
                    <InputSelect
                      label="For Which Level"
                      isRequired={true}
                      isDisabled={isLoading}
                      data={[
                        { value: "100", label: "100" },
                        { value: "200", label: "200" },
                        { value: "300", label: "300" },
                        { value: "400", label: "400" },
                      ]}
                      formValidateID="level"
                      form={form}
                    />
                  </div>
                  <div className="col-6">
                    <InputSelect
                      label="For Which Program"
                      isRequired={true}
                      isDisabled={isLoading}
                      data={[
                        {
                          value: "BSc. Telecommunications Engineering",
                          label: "BSc. Telecommunications Engineering",
                        },
                        {
                          value: "BSc. Computer Engineering",
                          label: "BSc. Computer Engineering",
                        },
                        {
                          value: "BSc. Mathematics",
                          label: "BSc. Mathematics",
                        },
                        {
                          value: "BSc. Electrical and Electronic Engineering",
                          label: "BSc. Electrical and Electronic Engineering",
                        },
                        {
                          value: "BSc. Informatics",
                          label: "BSc. Informatics",
                        },
                        {
                          value: "BSc. Information Technology",
                          label: "BSc. Information Technology",
                        },
                        {
                          value: "BSc. Mobile Computing",
                          label: "BSc. Mobile Computing",
                        },
                        {
                          value: "BSc. Computer Science",
                          label: "BSc. Computer Science",
                        },
                        {
                          value: "BSc. Software Engineering",
                          label: "BSc. Software Engineering",
                        },
                        {
                          value: "BSc. Business Information Systems",
                          label: "BSc. Business Information Systems",
                        },
                      ]}
                      formValidateID="program"
                      form={form}
                    />
                  </div>

                  <div className="col-6">
                    <InputTextField
                      label="GPS Longitude"
                      isRequired={true}
                      isDisabled={isLoading}
                      placeholder="-0.2234766"
                      formValidateID="gps_long"
                      form={form}
                    />
                  </div>

                  <div className="col-6">
                    <InputTextField
                      label="GPS Latitude"
                      isRequired={true}
                      isDisabled={isLoading}
                      placeholder="5.5961352"
                      formValidateID="gps_lat"
                      form={form}
                    />
                  </div>

                  <div className="mt-3 col-6 offset-3">
                    <InputButton
                      label="Save"
                      isLoading={isLoading}
                      //   onClickFunction={signIn}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Card>
      </Modal>
    </AppShell>
  );
}
