import { useState } from "react";
import {
  Navbar,
  Center,
  Tooltip,
  UnstyledButton,
  useMantineTheme,
  Modal,
  createStyles,
  Card,
  Stack,
  Text,
  Group,
} from "@mantine/core";
import { Apps, Logout, Users, Clock, Report } from "tabler-icons-react";
import { Link, useNavigate } from "react-router-dom";
import InputButton from "./input-button";

const useStyles = createStyles((theme) => ({
  link: {
    width: 50,
    height: 50,
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  active: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },
}));

const sideBarData = [
  { icon: Apps, label: "Dashboard", link: "/dashboard" },
  { icon: Users, label: "Students", link: "/students" },
  { icon: Clock, label: "Attendance", link: "/attendance" },
  { icon: Report, label: "Timetable", link: "/timetable" },
];

function NavbarLink({ icon: Icon, label, active, onClick, link }) {
  const { classes, cx } = useStyles();
  return (
    <Tooltip label={label} position="right" transitionDuration={0}>
      <Link to={link}>
        <UnstyledButton
          onClick={onClick}
          className={cx(classes.link, { [classes.active]: active })}
        >
          <Icon />
          {/* <img src={image} height={26} /> */}
        </UnstyledButton>
      </Link>
    </Tooltip>
  );
}

export default function SideMenuCards({ activeMenu }) {
  const theme = useMantineTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const links = sideBarData.map((link, index) => (
    <NavbarLink
      {...link}
      key={link.label}
      active={link.label === activeMenu}
      // onClick={() => setActive(index)}
    />
  ));

  const cancelLogout = () => {
    setModalOpen(false);
  };

  const logout = () => {
    navigate("/");
  };

  return (
    <>
      <Navbar height="100% -56px" width={{ base: 85 }} p="md">
        <Center>
          <p>Menu</p>
        </Center>
        <Navbar.Section grow mt={0}>
          <Stack justify="center" spacing={0}>
            {links}
          </Stack>
        </Navbar.Section>
        <Navbar.Section>
          <Stack justify="center" spacing={0}>
            <NavbarLink
              icon={Logout}
              label="Logout"
              onClick={() => setModalOpen(true)}
            />
          </Stack>
        </Navbar.Section>
      </Navbar>
      <Modal
        overlayColor={
          theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.dark[5]
        }
        overlayOpacity={0.55}
        overlayBlur={3}
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        // title={<p className="fs-4 m-0">Confirm Logout</p>}
        centered
        withCloseButton={false}
        overflow="inside"
        // closeOnClickOutside={false}
        // closeOnEscape={false}
        // size="xl"
      >
        <Card shadow="sm" radius="md" withBorder={false}>
          <div className="container">
            <div className="row">
              <Text className="text-center mb-4">Do you want to logout?</Text>
              <Center>
                <Group position="apart">
                  <InputButton
                    label="No"
                    // isLoading={isLoading}
                    onClickFunction={cancelLogout}
                  />
                  <InputButton
                    label="Yes"
                    // isLoading={isLoading}
                    onClickFunction={logout}
                  />
                </Group>
              </Center>
            </div>
          </div>
        </Card>
      </Modal>
    </>
  );
}
