import { useState } from "react";
import { Modal, Card, useMantineTheme, Divider } from "@mantine/core";
import { useForm } from "@mantine/form";
import InputButton from "./input-button";
import InputDatePicker from "./input-date";
import InputSelect from "./input-select";
import InputTextField from "./input-text";
import ModalSearchClient from "./modal-search-client";
import ModalSearchGuarantor from "./modal-search-guarantor";

export default function ModalNewLoan({ opened, isLoading, closeModal }) {
  const theme = useMantineTheme();
  const [openClientSearchModal, setOpenClientSearchModal] = useState(false);
  const [openGuarantorSearchModal, setOpenGuarantorSearchModal] =
    useState(false);
  const form = useForm({
    initialValues: {
      /////////////// VARIABLE FOR STEP 1 //////////////

      loan_id: "",
      interest: "",
      amount: "",
      duration: "",
      total_repayment_amount: "",
      payment_basis: "",
      payment_start_date: "",
      comment: "",
      client: "",
      client_id: "",
      guarantor: "",

      /////////////// VARIABLE FOR STEP 1 //////////////
    },

    // functions will be used to validate values at corresponding key
    validate: {
      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
      loan_id: (value) => value === "",
      interest: (value) => value === "",
      amount: (value) => value === "" || value < 100,
      duration: (value) => value === "",
      total_repayment_amount: (value) => value === "",
      payment_basis: (value) => value === "",
      payment_start_date: (value) => value === "",
      client: (value) => value === "",
      guarantor: (value) => value === "",
      ///////////////// VALIDATION FOR STEP 1 ////////////////////////
      ///////////////// VALIDATION FOR STEP 2 ////////////////////////
      ///////////////// VALIDATION FOR STEP 3 ////////////////////////
    },
  });

  const trggerSearchClientModal = () => {
    setOpenClientSearchModal(true);
  };

  const closeSearchClientModal = () => {
    setOpenClientSearchModal(false);
  };

  const trggerSearchGuarantorModal = () => {
    setOpenGuarantorSearchModal(true);
  };

  const closeSearchGuarantorModal = () => {
    setOpenGuarantorSearchModal(false);
  };

  const getClient = (clientInfo) => {
    form.setValues({
      client: clientInfo.name + " (" + clientInfo.client_id + ")",
      client_id: clientInfo.client_id,
    });
    closeSearchClientModal();
    console.log(clientInfo);
  };

  const getGuarantor = (guarantorInfo) => {
    form.setValues({
      guarantor: guarantorInfo.name + " (" + guarantorInfo.guarantor_id + ")",
    });
    closeSearchGuarantorModal();
    console.log(guarantorInfo);
  };

  return (
    <Modal
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.dark[5]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
      opened={opened}
      onClose={() => closeModal()}
      title={<p className="fs-4 m-0">New Loan Form</p>}
      // centered
      overflow="inside"
      closeOnClickOutside={false}
      closeOnEscape={false}
      size="xl"
    >
      {/* Modal content */}

      <ModalSearchClient
        opened={openClientSearchModal}
        closeModal={closeSearchClientModal}
        selectAction={getClient}
      />

      <ModalSearchGuarantor
        opened={openGuarantorSearchModal}
        closeModal={closeSearchGuarantorModal}
        selectAction={getGuarantor}
      />

      <Card shadow="sm" radius="md" withBorder={false}>
        <div className="container">
          <div className="row">
            <form
              onSubmit={form.onSubmit(() => {
                // nextStep();
              })}
            >
              <div className="row">
                <div className="col-12">
                  <Divider
                    mb="sm"
                    label="Loan Information"
                    labelPosition="center"
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label="Loan ID"
                    isDisabled={true}
                    showLoading={true}
                    formValidateID="loan_id"
                    form={form}
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label="Interest"
                    isDisabled={true}
                    showLoading={true}
                    formValidateID="interest"
                    form={form}
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label="Amount (GHS)"
                    isRequired={true}
                    type="number"
                    isDisabled={isLoading}
                    placeholder="1000"
                    formValidateID="amount"
                    form={form}
                  />
                </div>
                <div className="col-6">
                  <InputSelect
                    label="Duration"
                    isRequired={true}
                    isDisabled={isLoading}
                    data={[
                      { value: "1 Week", label: "1 Week" },
                      { value: "2 Weeks", label: "2 Weeks" },
                      { value: "3 Weeks", label: "3 Weeks" },
                      { value: "1 Month", label: "1 Month" },
                      { value: "3 Months", label: "3 Months" },
                      { value: "6 Months", label: "6 Months" },
                    ]}
                    formValidateID="duration"
                    form={form}
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label="Total Repayment Amount (GHS)"
                    isDisabled={true}
                    formValidateID="total_repayment_amount"
                    form={form}
                  />
                </div>
                <div className="col-6">
                  <InputSelect
                    label="Payment Basis"
                    isRequired={true}
                    isDisabled={isLoading}
                    data={[
                      { value: "Daily", label: "Daily" },
                      { value: "Weekly", label: "Weekly" },
                      { value: "Monthly", label: "Monthly" },
                    ]}
                    formValidateID="payment_basis"
                    form={form}
                  />
                </div>
                <div className="col-6">
                  <InputDatePicker
                    label="Payment Start Date"
                    isRequired={true}
                    isDisabled={isLoading}
                    formValidateID="payment_start_date"
                    form={form}
                  />
                </div>{" "}
                <div className="col-6">
                  <InputTextField
                    label="Comment"
                    formValidateID="comment"
                    form={form}
                  />
                </div>
                <div className="col-12">
                  <Divider
                    my="lg"
                    label="Client & Guarantor Information"
                    labelPosition="center"
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label="Client"
                    isRequired={true}
                    isDisabled={isLoading}
                    formValidateID="client"
                    form={form}
                    onInputclick={trggerSearchClientModal}
                    readOnly={true}
                  />
                </div>
                <div className="col-6">
                  <InputTextField
                    label="Guarantor"
                    isRequired={true}
                    isDisabled={isLoading}
                    formValidateID="guarantor"
                    form={form}
                    onInputclick={trggerSearchGuarantorModal}
                    readOnly={true}
                  />
                </div>
                <div className="mt-3 col-6 offset-3">
                  <InputButton
                    label="Save"
                    isLoading={isLoading}
                    //   onClickFunction={signIn}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Card>
    </Modal>
  );
}
