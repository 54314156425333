import {
  Progress,
  Table,
  Group,
  Text,
  ActionIcon,
  Menu,
  ScrollArea,
  Card,
  Badge,
} from "@mantine/core";
import {
  Printer,
  Wallet,
  DotsVertical,
  CashBanknote,
} from "tabler-icons-react";

export function LoansTable({ data }) {
  const rows = data.map((item, index) => (
    <tr key={item.id}>
      <td>
        <Group spacing="sm">
          <div>
            <Text size="sm" weight={500}>
              {index + 1}
            </Text>
          </div>
        </Group>
      </td>
      <td>
        <Text size="sm">
          <span className="fw-bold">{item.index_number}</span>
        </Text>
      </td>
      <td>
        <Text size="sm">
          {item.surname} {item.other_names}
        </Text>
      </td>
      <td>
        <Text size="sm"> {item.clocked_in}</Text>
      </td>
      <td>
        <Text size="sm">
          {" "}
          {item.gps_long},{item.gps_lat}
        </Text>
      </td>
      <td>
        <Text size="sm"> {item.clocked_out}</Text>
      </td>
    </tr>
  ));

  return (
    <Card shadow="sm" radius="md" withBorder={false}>
      <ScrollArea>
        <Table
          //   striped={true}
          //   highlightOnHover
          //   withBorder
          sx={{ minWidth: 800 }}
          verticalSpacing="xs"
        >
          <thead>
            <tr>
              <th>No</th>
              <th>Index Number</th>
              <th>Student Name</th>
              <th>Time Clocked In</th>
              <th>GPS Coordinate</th>
              <th>Time Clocked Out</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </ScrollArea>
    </Card>
  );
}
