import { createStyles, Text, Card, RingProgress, Group } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  card: {
    // backgroundColor:
    //   theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    lineHeight: 1,
  },

  lead: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 700,
    fontSize: 22,
    lineHeight: 1,
  },

  inner: {
    display: "flex",

    [theme.fn.smallerThan(350)]: {
      flexDirection: "column",
    },
  },

  ring: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",

    [theme.fn.smallerThan(350)]: {
      justifyContent: "center",
      marginTop: theme.spacing.md,
    },
  },
}));

const stats = {
  title: "Statistics",
  completed: 1887,
  total: 2334,
  stats: [
    {
      value: 447,
      label: "Active",
    },
    {
      value: 76,
      label: "Inactive",
    },
  ],
};

export default function CardStatsQucik({ colour }) {
  const { classes } = useStyles();
  const items = stats.stats.map((stat) => (
    <div key={stat.label}>
      <Text className={classes.label}>{stat.value}</Text>
      <Text size="xs" color="dimmed">
        {stat.label}
      </Text>
    </div>
  ));

  return (
    <Card withBorder p="xs" radius="md" className={classes.card}>
      <div className={classes.inner}>
        <div>
          <Text size="xl" className={classes.label}>
            {stats.title}
          </Text>
          <div>
            <Text className={classes.lead} mt={30}>
              {stats.completed}
            </Text>
            <Text size="xs" color="dimmed">
              Total
            </Text>
          </div>
          <Group mt="lg">{items}</Group>
        </div>

        <div className={classes.ring}>
          <RingProgress
            roundCaps
            thickness={6}
            size={150}
            sections={[
              {
                value: (stats.completed / stats.total) * 100,
                color: colour,
              },
            ]}
            label={
              <div>
                <Text
                  align="center"
                  size="lg"
                  className={classes.label}
                  sx={{ fontSize: 22 }}
                >
                  {((stats.completed / stats.total) * 100).toFixed(0)}%
                </Text>
                <Text align="center" size="xs" color="dimmed">
                  Active
                </Text>
              </div>
            }
          />
        </div>
      </div>
    </Card>
  );
}
